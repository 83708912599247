import type { VariantType } from "notistack";

import { useCallback, useMemo } from "react";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";

import prefixErrorToString, {
  errorToString,
} from "../helpers/prefixErrorToString";

// --------How to use--------
// import { useSnackbars } from 'parts/notification' // Import them both
// const notificationValues = useSnackbars() // Create a values for useSnackbars()
// notificationValues.handleOpen('message', 'type') // When you want to open the popup call this
//
// Possible values for type: error, warning, info, success
export const useSnackbars = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = useCallback(
    (key = undefined) => closeSnackbar(key),
    [closeSnackbar]
  );

  const dismiss = useCallback(
    (key) => (
      <Button
        color="inherit"
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    ),
    [closeSnackbar]
  );

  const getMessage = (ms) => {
    if (ms !== null && typeof ms == "object" && ms.response) {
      if (ms.response?.headers["content-type"]?.startsWith("text/html;")) {
        return "";
      }
      return ms.response.data;
    }

    if (typeof ms != "string" || ms[0] == "<") {
      return "";
    }

    return ms;
  };

  const defaultDuration = 3000;
  const handleOpen = useCallback(
    (ms, tp: VariantType = "default", duration = defaultDuration) => {
      let message = "";

      if (Array.isArray(ms)) {
        message = ms.find((message) => getMessage(message) != "");
      } else {
        message = getMessage(ms);
      }

      if (message == "" && tp == "error") {
        message = "Unhandled Error";
      }
      if (message == "" && tp == "success") {
        message = "Success";
      }

      if (
        tp == "error" ||
        tp == "warning" ||
        duration === 0 ||
        duration > defaultDuration
      ) {
        enqueueSnackbar(message, {
          variant: tp,
          persist: true,
          action: dismiss,
        });
      } else {
        enqueueSnackbar(message, { variant: tp, autoHideDuration: duration });
      }
    },
    [dismiss, enqueueSnackbar]
  );

  const dismissWithAction = (actionElement, key) => (
    <>
      {actionElement}
      <Button
        color="inherit"
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        Dismiss
      </Button>
    </>
  );

  const handleActionOpen = useCallback(
    (ms, action, tp: VariantType = "default") => {
      const newAction = (key) => {
        return dismissWithAction(action, key);
      };

      let message = "";

      if (Array.isArray(ms)) {
        message = ms.find((message) => getMessage(message) != "");
      } else {
        message = getMessage(ms);
      }

      if (message == "" && tp == "error") {
        message = "Unhandled Error";
      }
      if (message == "" && tp == "success") {
        message = "Success";
      }
      if (tp == "success") {
        enqueueSnackbar(message, {
          variant: tp,
          autoHideDuration: 30000,
          action: newAction,
        });
      } else {
        enqueueSnackbar(message, { variant: tp, persist: true, action });
      }
    },
    [enqueueSnackbar]
  );

  /**
   * Handles the user error format among other formats
   */
  const prefixError = useCallback(
    (prefix: string, error: Error) => {
      handleOpen(prefixErrorToString(prefix, error), "error");
    },
    [handleOpen]
  );

  const userError = useCallback(
    (error, ms = undefined) => {
      if (!ms) {
        const message = errorToString(error);
        handleOpen(message, "error");
        return;
      }

      if (error?.response?.data?.error) {
        handleOpen(error?.response?.data?.error, "error");
      } else {
        console.error(error);
        handleOpen(ms, "error");
      }
    },
    [handleOpen]
  );

  return useMemo(
    () => ({
      handleOpen,
      handleClose,
      handleActionOpen,
      enqueueSnackbar,
      prefixError,
      userError,
    }),
    [
      enqueueSnackbar,
      handleActionOpen,
      handleOpen,
      handleClose,
      prefixError,
      userError,
    ]
  );
};
