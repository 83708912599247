/**
 * Handles the user error format among other formats
 */
export const errorToString = (error: any): string => {
  // If the content-type is text/html; the error would be huge.
  // This prevents that
  if (error.response?.headers["content-type"]?.startsWith("text/html;")) {
    const requestIdParts = error.response.headers["x-request-id"].split("-");
    const requestId = requestIdParts[requestIdParts.length - 1];
    return requestId + " - Unknown server error - Contact IT";
  }

  // If error is an http response, set the error to the resonse data
  // If the response data is a string, this is what we want to show the user.
  if (error.response?.data) error = error.response.data;


  // Handle the first jsonapi error
  if (error.errors) {
    const requestId = error.requestId
    error = error.errors[0];
    const title = error.title ? error.title + " - " : ""

    return requestId + " - " + title + error.detail + ".";
  }

  // If the response data is an object with a message property,
  // set the error to the messge property.
  // This allows the server to send back additional data
  // besides the error message.
  if (error.message) return error.message;

  // When Rails config.consider_all_requests_local = false
  // then Rails sends back json errors in the format
  // { error: string, status: number }
  // f2x adds a requestId for easier debugging
  if (error.requestId && !error.error.includes(error.requestId)) {
    return error.requestId + " - " + error.error;
  }

  if (error.error) error = error.error;

  // Handles message in the format
  // {ap_invoice: ["must exist"]}
  if (error instanceof Object && Object.keys(error).length > 0) {
    return Object.keys(error)
      .map((key) => {
        if (error[key] instanceof Array) {
          return error[key].map((value: string) => `${key} ${value}`);
        } else if (error[key]) {
          return `${key} ${error[key]}`;
        } else {
          const err: any = new Error("Unable to prefix error");
          err.cause = error[key];
          throw err;
        }
      })
      .join("\n");
  }

  return error.toString();
};

/**
 * Handles the user error format among other formats
 */
const prefixErrorToString = (prefix: string, error: unknown): string =>
  prefix && error
    ? prefix + " - " + errorToString(error)
    : prefix || errorToString(error);

export default prefixErrorToString;
